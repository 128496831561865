import ApiService from "./api.service";
import { ResponseError, TripoliService } from "./tripoli.service";
import {
  postFormPromise,
  postJsonPromise,
  postJsonRequestResult,
} from "./base.service";
import { UserInstrumentAllocation } from "@/types/user-instrument-allocation";
import { InstrumentSignupStats } from "@/types/instrument-signup-stats";
import { SessionPublishedRoster } from "@/types/session-published-roster";
import { AutoAssignSuggestion } from "@/types/auto-assign-suggestion";
import { RequestResult } from "@/types/request-result";
import { SessionSongSlots } from "@/types/session-song-slots";
import { SessionSignupOverview } from "@/types/session-signup-overview";
import { SessionGroupWithDesc } from "@/types/session-group-with-desc";
import { SessionSummary } from "@/types/sessions";
import { UserGroupProfile } from "@/types/user-group-profile";
import NetworkBackedCache from "@/utils/network-backed-cache";
import { UserInstrumentSlotRegistrationWithDetails } from "@/types/user-instrument-slot-registration-with-details";
import { GroupDetails } from "@/types/group-details";

const SessionGroupEndpoint = {
  joinGroup: "/v1/groups/join-group",
  details: "/v1/groups/details",
  adminDetails: "/v1/groups/admin/details",
  userApproved: "/v1/groups/user/approved",
};

const _userSlotRegistrations = NetworkBackedCache<
  RequestResult<Array<UserInstrumentSlotRegistrationWithDetails>>
>("", {
  memCacheExpiresMs: 60000 * 60 * 60, // 60 minutes;
  fetchSingleUrl: (key: string) => {
    const keyItems = key.split("|");
    const sessionId = keyItems[0];
    const userId = keyItems[1];
    return `/Session/UserSlotRegistrations/?sessionId=${sessionId}&userId=${userId}&noCache=${new Date()}`;
  },
  fetchSingleMethod: "post",
});
const GroupService = {
  clearUserSessionCache: async function (sessionId: any, userId: any) {
    await _userSlotRegistrations.del(sessionId + "|" + userId);
  },
  activeUserIsSessionAdmin: async function (sessionId: number) {
    return postFormPromise("/v1/Session/ActiveUserIsSessionAdmin/", {
      sessionId: sessionId,
    });
  },
  userIsApproved: async function (id: number) {
    return postFormPromise<boolean>(
      `${SessionGroupEndpoint.userApproved}/${id}`,
      {}
    );
  },
  joinGroup: async function (id: any) {
    return postFormPromise(`${SessionGroupEndpoint.joinGroup}/${id}`, {});
  },
  allGroups: async function () {
    return postFormPromise<RequestResult<SessionGroupWithDesc[]>>(
      "/public/AllGroups/",
      {}
    );
  },
  publicSessions: async function () {
    return postFormPromise<RequestResult<SessionSummary[]>>(
      "/public/Sessions/",
      {}
    );
  },
  confirmRosterAssignments: async function (
    sessionId: number,
    rosterId: number
  ) {
    return postJsonPromise(
      `/v1/Session/ConfirmAssignment/?sessionId=${sessionId}&rosterId=${rosterId}`,
      {}
    );
  },
  messagesForGroup: async function (groupId: any) {
    return postJsonPromise(`/SessionGroup/AdminAllMessages/${groupId}`, {});
  },
  details: async function (groupId: number) {
    return ApiService.get(`${SessionGroupEndpoint.details}/${groupId}`);
  },
  adminDetails: async function (groupId: number): Promise<GroupDetails> {
    return ApiService.get(`${SessionGroupEndpoint.adminDetails}/${groupId}`);
  },

  usersOnlineForGroup: async function (groupId: any, max: number) {
    return postFormPromise(
      `/SessionGroup/OnlineUsersForGroup?groupId=${groupId}&max=${max}`,
      {}
    );
  },
  updateSlotNote: async function (sessionId: any, slotId: any, details: any) {
    return postFormPromise(
      `/SessionSong/UpdateSlotNote?sessionId=${sessionId}&instrumentSlotId=${slotId}`,
      { details }
    );
  },
  allUserSignupsWithRoster: async function (sessionId: any, rosterId: any) {
    return postJsonPromise(
      `/Session/AllUserSignupsWithRoster?sessionId=${sessionId}&rosterId=${rosterId}`,
      {}
    );
  },
  createNewRoster: async function (data: any) {
    return postFormPromise("/Session/NewRoster/", data);
  },
  sessionSong: async function (id: any, sessionId: any) {
    return postFormPromise(
      "/SessionSong/SessionSong/" + id + "?sessionId=" + sessionId,
      {}
    );
  },
  setGroupAdminRights: async function (
    groupId: any,
    userId: any,
    isAdmin: boolean
  ) {
    return postFormPromise(
      `/SessionGroup/SetAdminRights/?groupId=${groupId}&userId=${userId}`,
      { isAdmin }
    );
  },
  setSongCut: async function (id: any, sessionId: any, value: boolean) {
    return postFormPromise(
      "/SessionSong/CutSong?sessionSongId=" +
        id +
        "&sessionId=" +
        sessionId +
        "&value=" +
        value,
      {}
    );
  },
  deleteRoster: async function (id: any) {
    return postFormPromise(
      "/Session/DeleteSessionRoster/?rosterId=" + id,
      null
    );
  },
  loadGroupsForUser: async function () {
    return postFormPromise("/SessionGroup/ListForUser/", {});
  },
  location: async function (id: any) {
    return postFormPromise("/SessionGroup/Location/" + id, {});
  },
  editLocation: async function (data: any) {
    return postFormPromise("/SessionGroup/EditLocation/", data);
  },
  publishedRosterForUser: async function (sessionId: any) {
    return postFormPromise<SessionPublishedRoster>(
      "/v1/Session/GetPublishedRosterForUser/?sessionId=" + sessionId,
      {}
    );
  },
  allRegisteredUsersForSlot: async function (sessionId: any, slotId: any) {
    return postFormPromise(
      `/SessionSong/AllRegisteredUsersForSlot/?sessionId=${sessionId}&slotId=${slotId}`,
      {}
    );
  },
  unAssignSlot: async function (
    sessionId: any,
    rosterId: any,
    instrumentSlotId: any
  ) {
    return postJsonPromise(
      `/SessionSong/UnassignSlot/?sessionId=${parseInt(
        sessionId
      )}&rosterId=${parseInt(rosterId)}&instrumentSlotId=${parseInt(
        instrumentSlotId
      )}`,
      {}
    );
  },
  assignUserToSlotForDraft: async function (
    sessionId: any,
    rosterId: any,
    data: any
  ) {
    return postJsonPromise(
      `/Session/AssignUserToSlotForDraft/?sessionId=${sessionId}&rosterId=${rosterId}`,
      data
    );
  },
  assignUserToSlot: async function (data: any) {
    return postFormPromise(`/SessionSong/AssignUserToSlot/`, data);
  },
  nextDraftSuggestion: async function (
    sessionId: any,
    rosterId: any,
    slotIndex: number,
    priority: number,
    instrumentIndex: number,
    passNo: number,
    draftSettings: any
  ) {
    return postJsonPromise<RequestResult<AutoAssignSuggestion>>(
      `/v1/Draft/NextAssignSuggestion/?sessionId=${sessionId}&rosterId=${rosterId}&slotIndex=${slotIndex}&priority=${priority}&instrumentIndex=${instrumentIndex}&passNo=${passNo}`,
      draftSettings
    );
  },

  startInstrumentDraft: async function (
    sessionId: any,
    rosterId: any,
    draftSettings: any
  ) {
    return postJsonPromise(
      `/Session/StartInstrumentDraft/?sessionId=${sessionId}&rosterId=${rosterId}`,
      draftSettings
    );
  },

  draftFillRemainingSlots: async function (
    sessionId: any,
    rosterId: any,
    draftSettings: any
  ) {
    return postJsonPromise(
      `/Session/DraftFillRemainingSlots/?sessionId=${sessionId}&rosterId=${rosterId}`,
      draftSettings
    );
  },
  assignmentsForDraft: async function (
    sessionId: any,
    rosterId: any,
    userId: number,
    draftSettings: any
  ) {
    return postJsonPromise(
      `/Session/AssignmentsForDraft/?sessionId=${sessionId}&rosterId=${rosterId}&userId=${userId}`,
      draftSettings
    );
  },

  rosterAssignment: async function (
    sessionId: any,
    rosterId: any,
    slotId: any
  ) {
    return postFormPromise(
      `/Session/RosterAssignment/?sessionId=${sessionId}&rosterId=${rosterId}&slotId=${slotId}`,
      {}
    );
  },

  assignUserToSlotNotRegistered: async function (data: any) {
    return postFormPromise(`/SessionSong/AssignUserToSlotNotRegistered/`, data);
  },
  sessionSongSlots: async function (sessionId: any): Promise<SessionSongSlots> {
    return postJsonRequestResult<SessionSongSlots>(
      `/Session/SessionSongSlots/?sessionId=${sessionId}`,
      null
    );
  },
  userSlotRegistrationsForDraft: async function (
    sessionId: any,
    userId: any,
    rosterId: any,
    draftSettings: any
  ) {
    return postJsonPromise(
      `/Session/UserSlotRegistrationsForDraft/?sessionId=${sessionId}&userId=${userId}&rosterId=${rosterId}&noCache=` +
        new Date(),
      draftSettings
    );
  },

  userSlotRegistrations: async function (
    sessionId: any,
    userId: any
  ): Promise<
    RequestResult<Array<UserInstrumentSlotRegistrationWithDetails>> | undefined
  > {
    return _userSlotRegistrations.get(sessionId + "|" + userId);
  },
  submitPicks: async function (sessionId: any, userId: any) {
    return postFormPromise(
      `/Session/SubmitPicks/?sessionId=${sessionId}&userId=${userId}&noCache=` +
        new Date(),
      null
    );
  },

  usersForGroup: async function (
    groupId: any
  ): Promise<RequestResult<Array<UserGroupProfile>>> {
    try {
      return ApiService.get<RequestResult<Array<UserGroupProfile>>>(
        `/SessionGroup/Users/${groupId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  allSessionInstrumentRegistrations: async function (sessionId: any) {
    return postFormPromise(
      `/v1/Session/GetSessionInstrumentRegistrations/?sessionId=${sessionId}&noCache=` +
        new Date(),
      null
    );
  },
  /**
   *  Update the max allocation for users per instrument for a session
   * @param sessionId - The session id
   * @param data - A dictionary of userIds with the value as a dictionary of instruments keys for max count
   */
  updateUsersMaxInstrumentAllocation: async function (
    sessionId: number,
    data: UserInstrumentAllocation[]
  ) {
    return postJsonPromise(
      `/v1/Session/UpdateAllocationMax/?sessionId=${sessionId}`,
      data
    );
  },

  resetUsersMaxInstrumentAllocation: async function (sessionId: number) {
    return postJsonPromise(
      `/v1/Session/ResetAllocationMax/?sessionId=${sessionId}`,
      {}
    );
  },
  allSessionSlotSignupDetails: async function (sessionId: any) {
    return postJsonRequestResult<SessionSignupOverview>(
      `/Session/GetSessionSlotSignupDetails/?sessionId=${sessionId}&noCache=` +
        new Date(),
      null
    );
  },
  userWildcardRegistrations: async function (sessionId: any, userId: any) {
    return postFormPromise(
      `/Session/InstrumentWildcardSingupsForUser/?sessionId=${sessionId}&userId=${userId}&noCache=` +
        new Date(),
      null
    );
  },
  // unregisterUserFromSession: async function (
  //   sessionId: any,
  //   userId: any,
  //   reason = ""
  // ) {
  //   return postFormPromise(
  //     `/v1/Session/UnRegisterUser/?sessionId=${sessionId}&userId=${userId}&noCache=` +
  //       new Date(),
  //     { reason }
  //   );
  // },
  unregisterWildcardInstrument: async function (
    registrationId: any,
    sessionId: any,
    userId: any
  ) {
    return postFormPromise(
      `/Session/UnregisterWildcardInstrument/?sessionId=${sessionId}&signupId=${registrationId}&userId=${userId}&noCache=` +
        new Date(),
      null
    );
  },
  registerWildcardInstrument: async function (
    sessionId: any,
    instrumentId: any,
    userId: any
  ) {
    return postFormPromise(
      `/Session/RegisterWildcardInstrument/?sessionId=${sessionId}&instrumentId=${instrumentId}&userId=${userId}&noCache=` +
        new Date(),
      null
    );
  },
  updateUserRegistrationDetail: async function (
    sessionId: any,
    userId: any,
    instrumentSlotRegistrationId: any,
    details: any
  ) {
    return postFormPromise(
      `/SessionSong/UpdateUserSlotRegistrationDetail/?sessionId=${sessionId}&userId=${userId}&instrumentSlotRegistrationId=${instrumentSlotRegistrationId}`,
      {
        details,
      }
    );
  },
  adminRemoveUserFromGroup: async function (groupId: any, userId: any) {
    return postJsonPromise(`/SessionGroup/AdminRemoveUserFromGroup`, {
      GroupId: groupId,
      UserId: userId,
    });
  },
  adminApproveUser: async function (groupId: any, userId: any) {
    return postJsonPromise(`/SessionGroup/AdminApproveUserForGroup`, {
      GroupId: groupId,
      UserId: userId,
    });
  },
  setAdminInstrumentRating: async function (
    groupId: any,
    instrumentId: any,
    userId: any,
    rating: any
  ) {
    return postJsonPromise(`/SessionGroup/SetAdminInstrumentRating`, {
      GroupId: groupId,
      InstrumentId: instrumentId,
      UserId: userId,
      Rating: rating,
    });
  },
  userInstrumentsForGroup: async function (groupId: any, userId: any) {
    return postFormPromise(
      `/SessionGroup/UserInstrumentsForGroup?groupId=${groupId}&userId=${userId}`,
      null
    );
  },
  submitGroupPost: async function (
    groupId: any,
    postId: any,
    text: any,
    published: boolean
  ) {
    return postFormPromise(`/SessionGroup/UpdatePost/`, {
      groupId,
      postId,
      text,
      published,
    });
  },
  getGroupDetailAssets: async function (groupId: any) {
    try {
      return ApiService.get<any>(`/SessionGroup/GroupDetailAssets/${groupId}`);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  addressLookup: async function (state: any) {
    return postFormPromise(`/SessionGroup/AddressLookup/`, state);
  },
  getSessionImageAsset: async function (sessionId: any) {
    try {
      return ApiService.get<any>(`/Session/SessionImageAsset/${sessionId}`);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  setSessionImage: async function (
    base64Data: any,
    imageSize: any,
    sessionId: any,
    assetId: any,
    groupId: any
  ) {
    return new Promise((resolve, reject) => {
      return ApiService.postForm(
        "/Session/UploadImage/?sessionId=" + sessionId + "&assetId=" + assetId,
        {
          Base64Image: base64Data,
          SessionGroupId: groupId,
          SizeBytes: imageSize,
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  setGroupImage: async function (
    base64Data: any,
    imageSize: any,
    groupId: any,
    assetId: any
  ) {
    return new Promise((resolve, reject) => {
      return ApiService.postForm(
        "/SessionGroup/UploadGroupProfileImage/?assetId=" + assetId,
        {
          Base64Image: base64Data,
          SessionGroupId: groupId,
          SizeBytes: imageSize,
        }
      )
        .then((res) => {
          TripoliService.clearGroupDetailCache(groupId).then(() => {
            resolve(res);
          });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  setGroupDescriptionAsset: async function (groupId: any, postId: any) {
    try {
      await TripoliService.clearGroupDetailCache(groupId);
      return await ApiService.post(
        `/SessionGroup/SetGroupDesc/?sessionGroupId=${groupId}&assetId=${postId}`,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  publishRoster: async function (rosterId: any) {
    return postFormPromise("/Session/PublishRoster/?rosterId=" + rosterId, {});
  },
  rostersForSession: async function (sessionId: any) {
    try {
      return ApiService.get<any>(
        "/Session/GetSessionRosters?sessionId=" + sessionId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  rosterAssignments: async function (rosterId: any) {
    try {
      return ApiService.get<any>(
        "/Session/RosterAssignments?rosterId=" + rosterId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  instrumentSignupStats: async function (
    sessionId: any
  ): Promise<InstrumentSignupStats[]> {
    try {
      return ApiService.postJson<InstrumentSignupStats[]>(
        "/v1/Session/SessionInstrumentStats/?sessionId=" + sessionId,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
};
export { GroupService, SessionGroupEndpoint };
