import { SessionInstrumentSignupState } from "@/types/session-instrument-signup-state";
import ApiService from "./api.service";
import {
  postFormPromise,
  postJsonPromise,
  postJsonRequestResult,
} from "./base.service";
import { RequestResult } from "@/types/request-result";
import { SessionWithLocationDetails } from "@/types/session-with-location-details";
import { SessionDetailsDTO } from "@/types/session-details-dto";
import { EditSong } from "@/types/edit-song";
import { UserSessionRegistration } from "@/types/user-session-registration";
import { SessionSong } from "@/types/session-song";
import { RegisteredUserId } from "@/types/registered-userids";
import { InstrumentSignupStateEnum } from "@/types/instrument-signup-state-enum";
import NetworkBackedCache from "@/utils/network-backed-cache";
import { GroupService } from "./group.service";
import { SessionPayment } from "@/types/session-payment";

const SessionAdminRoot = "/v1/session-admin";
const SessionRoot = "/v1/sessions";
const SongRoot = "/v1/song";

const SessionAdminEndpoint = {
  AddSong: `${SessionAdminRoot}/add-song/`,
  RemoveSlot: `${SessionAdminRoot}/remove-slot/`,
  AddSlot: `${SessionAdminRoot}/add-inst-slot/`,
  SetSlotCore: `${SessionAdminRoot}/set-slot-required/`,
};

const SessionEndpoint = {
  Details: `${SessionRoot}/details/`,
  UnRegister: `${SessionRoot}/unregister-user/`,
  RegisterPart: `${SessionRoot}/register-part/`,
  UnRegisterPart: `${SessionRoot}/unregister-part/`,
};

const SongEndpoint = {
  Add: `${SongRoot}/add-new-song-with-spotify`,
};

const _sessionCache = NetworkBackedCache<SessionWithLocationDetails>("", {
  memCacheExpiresMs: 60000 * 60 * 60, // 60 minutes;
  fetchSingleUrl: "/Session/",
  fetchSingleMethod: "get",
});
const _sessionDetailCache = NetworkBackedCache<SessionDetailsDTO>("", {
  memCacheExpiresMs: 60000 * 60 * 60, // 60 minutes;
  fetchSingleUrl: SessionEndpoint.Details,
  fetchSingleMethod: "get",
});
const _registeredUserCache = NetworkBackedCache<
  RequestResult<RegisteredUserId[]>
>("", {
  memCacheExpiresMs: 60000 * 60 * 5, // 5 minutes;
  fetchSingleUrl: "/Session/RegisteredUsersForSession?sessionId=",
  fetchSingleMethod: "post",
});
const _sessionSongCache = NetworkBackedCache<Array<SessionSong>>("", {
  memCacheExpiresMs: 60000 * 60 * 60, // 60 minutes;
  fetchSingleUrl: "/Session/SessionSongs?sessionId=",
  fetchSingleMethod: "get",
  localStoreName: "sessionSongCache",
});

class ResponseError extends Error {
  errorCode: any;
  errorMessage: any;
  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const SessionService = {
  clearSongCache: async function name(sessionId: any) {
    await _sessionSongCache.del(sessionId.toString());
  },
  clearSessionCache: async function (sessionId: any) {
    await _sessionCache.del(sessionId.toString());
    await _sessionDetailCache.del(sessionId.toString());
    await _registeredUserCache.del(sessionId.toString());
  },
  getSessionInstrumentSignupStates: async function (sessionId: number) {
    try {
      return ApiService.get<Array<SessionInstrumentSignupState>>(
        "/Session/GetSessionInstrumentSignupStates?sessionId=" + sessionId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  getSessionInstrumentSignupState: async function (
    sessionId: number,
    instrumentId: number
  ) {
    try {
      return ApiService.get<SessionInstrumentSignupState>(
        `/Session/GetSessionInstrumentSignupState?sessionId=${sessionId}&instrumentId=${instrumentId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  unregisterUserFromSession: async function (
    sessionId: any,
    userId: any,
    reason = ""
  ) {
    return postJsonPromise(
      `${SessionEndpoint.UnRegister}?noCache=` + new Date(),
      {
        ForUserId: userId,
        SessionId: sessionId,
        Reason: reason ?? "",
      }
    );
  },
  updateSessionInstrumentSignupState: async function (
    sessionId: number,
    instrumentId: number,
    newState: InstrumentSignupStateEnum,
    reason: string
  ) {
    try {
      await SessionService.clearSessionCache(sessionId);
      return ApiService.postJson<RequestResult<any>>(
        `/Session/UpdateSessionInstrumentSignupState?sessionId=${sessionId}&instrumentId=${instrumentId}&newState=${newState}&reason=${reason}`,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  manualPayment: async function (payment: SessionPayment) {
    try {
      return ApiService.postJson("/v1/payment/manual-payment/", payment);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  session: async function (
    sessionId: any,
    forceReload = false
  ): Promise<SessionWithLocationDetails | undefined> {
    try {
      if (forceReload) {
        _sessionCache.del(sessionId);
      }
      return _sessionCache.get(sessionId);
      return ApiService.get<SessionWithLocationDetails>(
        "/Session/" + sessionId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  sessionDetails: async function (
    sessionId: any
  ): Promise<SessionDetailsDTO | undefined> {
    try {
      return _sessionDetailCache.get(sessionId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  addSongToSession: async function (sessionId: any, songId: number) {
    try {
      await _sessionSongCache.del(sessionId);
      return ApiService.postJson<SessionSong>(SessionAdminEndpoint.AddSong, {
        SessionId: sessionId,
        SongId: songId,
        RequiredPermission: 1,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  editSessionSong: async function (
    sessionId: any,
    sessionSongId: number,
    song: EditSong
  ) {
    try {
      return ApiService.postForm<any>(
        `/Session/EditSong/?sessionId=${sessionId}&sessionSongId=${sessionSongId}&youtubeLink=${song.youtubeLink}`,
        {
          song,
        }
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  clearAndSetInstrumentSlots: async function (
    sessionId: any,
    songId: number,
    previousSessionId: number
  ) {
    try {
      return ApiService.postForm<any>(
        `/Session/SetInstrumentsFromPreviousSessionSong/?sessionId=${sessionId}&songId=${songId}&previousSessionId=${previousSessionId}`,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  previousSongSlotConfigurations: async function (
    songId: number,
    sessionId: any
  ) {
    try {
      return ApiService.postForm<any>(
        `/Session/PreviousSongSlotConfigurations/?songId=${songId}&sessionId=${sessionId}`,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  addNewSongToSession: async function (sessionId: any, song: EditSong) {
    try {
      await SessionService.clearSongCache(sessionId);
      return postJsonPromise(SongEndpoint.Add, {
        SessionId: sessionId,
        Title: song.title,
        ArtistName: song.artistName,
        SpotifyTrackId: song.spotifyId,
        YouTubeLink: song.youtubeLink,
        DurationSeconds: song.durationSeconds,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  removeSongFromSession: async function (sessionId: any, songId: number) {
    try {
      await SessionService.clearSongCache(sessionId);
      return ApiService.get<any>(
        "/Session/RemoveSong/?sessionId=" + sessionId + "&songId=" + songId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  reorderSong: async function (sessionId: any, songId: any, newIndex: any) {
    try {
      await SessionService.clearSongCache(sessionId);
      return ApiService.get<any>(
        "/Session/Reorder/?sessionId=" +
          sessionId +
          "&songId=" +
          songId +
          "&newIndex=" +
          newIndex
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  setRegistrationPriority: async function (
    sessionId: any,
    userId: any,
    registrationIds: number[]
  ) {
    try {
      await GroupService.clearUserSessionCache(sessionId, userId);
      return ApiService.postJson<any>("/Session/SetRegistrationPriority/", {
        sessionId: sessionId,
        userId: userId,
        instrumentSlotUserSignupIds: registrationIds,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  reorderRegistration: async function (
    sessionId: any,
    instrumentSlotUserSignupId: any,
    newPriority: any,
    userId: any
  ) {
    try {
      await GroupService.clearUserSessionCache(sessionId, userId);
      return ApiService.get<any>(
        "/Session/ReorderRegistrationPriority/?sessionId=" +
          sessionId +
          "&instrumentSlotUserSignupId=" +
          instrumentSlotUserSignupId +
          "&newPriority=" +
          newPriority +
          "&userId=" +
          userId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  addSession: async function (session: any) {
    try {
      return ApiService.postForm<any>("/Session/AddSession/", session);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  editSession: async function (session: any) {
    try {
      return ApiService.postForm<any>("/Session/EditSession/", session);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  getUserSessionRegistration: async function (sessionId: any, userId: any) {
    try {
      return ApiService.get<RequestResult<UserSessionRegistration>>(
        "/Session/UserRegistrationForSession?sessionId=" +
          sessionId +
          "&userId=" +
          userId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  addOrUpdateSessionRegistration: async function (
    sessionId: any,
    userId: any,
    lower: any,
    upper: any,
    notes: string
  ) {
    try {
      return ApiService.postForm<any>(
        "/Session/AddOrUpdateSessionRegistration/",
        {
          sessionId,
          userId,
          SongAssignMin: lower,
          SongAssignMax: upper,
          Notes: notes,
        }
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  sessionSongs: async function (sessionId: any): Promise<Array<SessionSong>> {
    try {
      return postJsonRequestResult<SessionSong[]>(
        "/v1/Session/Songs?sessionId=" + sessionId,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  draft: async function (sessionId: any, rosterId: any) {
    try {
      return await ApiService.get<any>(
        "/Session/AutoAssign?sessionId=" + sessionId + "&rosterId=" + rosterId
      );
    } catch (error: any) {
      return error;
    }
  },
  sessionSongsWithSummary: async function (sessionId: any) {
    try {
      return _sessionSongCache.get(sessionId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  instrumentsForSong: async function (sessionId: any, sessionSongId: any) {
    try {
      return ApiService.get<any>(
        `/Session/Instruments?sessionId=${sessionId}&sessionSongId=${sessionSongId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  instrumentsForSongWithAssignments: async function (
    sessionId: any,
    sessionSongId: any
  ) {
    try {
      return ApiService.get<any>(
        `/Session/InstrumentsWithAssignments?sessionId=${sessionId}&sessionSongId=${sessionSongId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  sessionUserInstrumentMax: async function (
    sessionId: any,
    instruments: string
  ) {
    return postFormPromise(
      `/Session/GetSessionUserInstrumentMax/?sessionId=${sessionId}&noCache=` +
        new Date(),
      { instruments }
    );
  },
  registerUserForSlot: async function (
    sessionId: any,
    slotId: any,
    userId: any,
    priority: number
  ) {
    try {
      return ApiService.postJson<RequestResult<any>>(
        `${SessionEndpoint.RegisterPart}`,
        {
          Priority: priority,
          SessionId: sessionId,
          SlotId: slotId,
          ForUserId: userId,
        }
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  unRegisterUserForSlot: async function (
    sessionId: any,
    instrumentSlotId: any,
    userId: any
  ) {
    try {
      return ApiService.postJson<RequestResult<any>>(
        `${SessionEndpoint.UnRegisterPart}`,
        {
          SessionId: sessionId,
          SlotId: instrumentSlotId,
          ForUserId: userId,
        }
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  removeUserFromSlot: async function (
    sessionId: any,
    slotId: any,
    userId: any
  ) {
    try {
      return ApiService.get<any>(
        `/SessionSong/RemoveUserForSlot?sessionId=${sessionId}&slotId=${slotId}&userId=${userId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  addInstrumentToSong: async function (
    sessionId: number,
    sessionSongId: number,
    instrumentId: any,
    details = ""
  ) {
    try {
      return ApiService.postJson<any>(SessionAdminEndpoint.AddSlot, {
        sessionId,
        sessionSongId,
        instrumentId,
        details,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  setSlotCore: async function (
    instrumentSlotId: number,
    sessionId: number,
    isCore: boolean
  ) {
    try {
      return ApiService.postJson<any>(SessionAdminEndpoint.SetSlotCore, {
        sessionId,
        instrumentSlotId,
        Required: isCore,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  removeInstrumentFromSong: async function (
    sessionId: any,
    sessionSongId: any,
    instrumentSlotId: any
  ) {
    try {
      return ApiService.postJson<any>(SessionAdminEndpoint.RemoveSlot, {
        sessionId,
        sessionSongId,
        instrumentSlotId,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  registrationsForSong: async function (
    sessionId: any,
    sessionSongId: any,
    slotId: any
  ) {
    try {
      return ApiService.get<any>(
        `/Songs/SlotRegistrations?sessionId=${sessionId}&sessionSongId=${sessionSongId}&slotId=${slotId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  registeredSessionUserIds: async function (sessionId: any) {
    try {
      if (!sessionId) return;
      return _registeredUserCache.get(sessionId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
};

export { SessionService, ResponseError };
